export const FILTERS_TABLE = {
    string: 'string',
    string_with_empty: 'string_with_empty',
    strict_string: 'strict_string',
    number: 'number',
    number_with_empty: 'number_with_empty',
    list: 'list',
    list_with_empty: 'list_with_empty',
    boolean: 'boolean',
    date: 'date',
    select: 'select',
    select_with_empty: 'select_with_empty',
    caller_profile: 'caller_profile',
    parameters: 'parameters',
    tags: 'tags',
    custom_parameters: 'custom_parameters',
    session_data: 'session_data',
}
